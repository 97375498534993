import axios from "@/axios.js";
/* eslint-disable */
export default {

    SaveContractChanges(id, item) {
        return new Promise((resolve, reject) => {
            axios.post(`nexlaw/contract-review/${id}/save`, item)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    UploadFile(item) {
        return new Promise((resolve, reject) => {
            console.log(item)
            axios.post(`nexlaw/contract-review`, item, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetContractReview(item) {
        return new Promise((resolve, reject) => {
            axios.get(`nexlaw?type=contract_review` + item)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    ViewContractReview(id) {
        return new Promise((resolve, reject) => {
            axios.get(`nexlaw/` + id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    sendQuestion(id, item) {
        return new Promise((resolve, reject) => {

            axios.post(`nexlaw/contract-review/${id}/ask`, item
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    changeClause(id, value, item) {
        return new Promise((resolve, reject) => {

            axios.post(`nexlaw/contract-review/${id}/improve/${value}`, item
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    }

}